// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock, faTrash, faMeh } from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faClock, faAngleLeft, faTrash, faMeh)
// library.add(faAngleLeft)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy, {
	defaultIconComponent: "font-awesome-icon",
	defaultIconPack: "fas",
})
// import '..stylesheet/application'
import router from 'routes'
// import Router from 'vue-router'
import App from 'components/App'

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: 'UA-94040280-1' }
});


document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('vueapp')
  if (element) {
    new Vue({
      el: element,
      router,
      render: h => h(App)
    })
  }
})
