import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import PassagesIndex from 'components/passages/Index'
// import HomeShow from 'components/home/Show'

export default new Router({
  // mode: history,
  routes: [
    {
      path: '/',
      component: PassagesIndex
    },
    // {
    //   path: '/home',
    //   name: 'home_index',
    //   component: HomeIndex
    // },
    // {
    //   path: '/home/:id',
    //   name: 'home_show',
    //   component: HomeShow,
    //   props: route => ({
    //     id: Number(route.params.id)
    //   })
    // },
  ]
})
