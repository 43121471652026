import axios from 'axios'

export default () => {
  const userToken = localStorage.getItem('user-token')
  const userEmail = localStorage.getItem('user-email')
  return axios.create({
    baseURL: '/api/v1',
    headers: {
      'Authorization': `Token token=${userToken}, email=${userEmail}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
}
