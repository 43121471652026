<template>
  <div class="container">
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <div class="columns">
      <div class="column is-three-fifths">

        <b-field grouped>
          <div class="label" style="margin-right: 0.75rem;">Passage</div>
          <b-select 
              size="is-small" 
              placeholder="Select a passage to paste ..." 
              @input="getPassage(selectedPassage)" 
              v-model="selectedPassage">
            <option v-for="p in defaultPassages" :value="p.id">{{p.name}}</option>
          </b-select>
          <p class="control" v-if="passageText">
            <b-button 
                size="is-small" 
                type="is-danger"
                icon-left="trash"
                @click="deletePassage">
              Delete
            </b-button>
          </p>
        </b-field>

        <b-field>
          <b-input
                maxlength="100000"
                type="textarea"
                rows="25"
                placeholder="Paste your own text here or select a sample passage from the drop-down menu above ..."
                v-model="passageText"
                @input="getStats()">
          </b-input>
        </b-field>
      </div>

      <div class="column is-two-fifths">
        <b-field grouped>
          <div class="label">Stats</div>
        </b-field>
        <div class="box">
          <b-field>
            <b-switch 
                v-model="fkReadingLevelSwitch">
              Flesch-Kincaid Reading Level
            </b-switch>
          </b-field>
          <hr />
          <b-field grouped group-multiline>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-black">
                  Word Count
                </b-tag>
                <b-tag type="is-primary">
                  {{ wordCount }}
                </b-tag>
              </b-taglist>
            </div>
            <div class="control" v-if="fkReadingLevelSwitch">
              <b-taglist attached>
                <b-tag type="is-black">
                  FK Score
                </b-tag>
                <b-tag type="is-primary">
                  {{ fkScore }}
                </b-tag>
              </b-taglist>
            </div>
            <div class="control" v-if="fkReadingLevelSwitch">
              <b-taglist attached>
                <b-tag type="is-black">
                  FK Grade
                </b-tag>
                <b-tag type="is-primary">
                  {{ fkGrade }}
                </b-tag>
              </b-taglist>
            </div>
            <div class="control" v-if="fkReadingLevelSwitch">
              <b-taglist attached>
                <b-tag type="is-black">
                  FK Notes
                </b-tag>
                <b-tag type="is-primary">
                  {{ fkNotes }}
                </b-tag>
              </b-taglist>
            </div>
          </b-field>
        </div>

        <div class="box">
          <b-field>
            <b-switch 
                v-model="edlWordsSwitch" 
                @input="getStats()">
              EDL Core Vocabulary
            </b-switch>
          </b-field>
          <div v-if="edlWordsSwitch">
            <b-field grouped>
              <b-select 
                  placeholder="Select a subject" 
                  size="is-small"
                  v-model="selectedGrade"
                  @input="getStats()">
                <option :value="0">Auto</option>
                <option
                    v-for="option in gradeLevels"
                    :value="option.id"
                    :key="option.id">
                    {{ option.grade }}
                </option>
              </b-select>
              <b-field>
                <b-checkbox 
                    v-model="edlRelatedWordsCheckbox" 
                    @input="getStats()" 
                    size="is-small">
                  include plural words
                </b-checkbox>
              </b-field>
            </b-field>
            <hr />
            <div 
                class="content has-text-grey has-text-centered" 
                v-if="edlWords.length == 0">
              <p>Nothing found yet.</p>
              <p v-if="selectedGrade == 0">Good testable words will be 1-2 grades above the student's own grade level.</p>
              <p v-else>Good testable words will be 1-2 grades above Grade {{ selectedGrade }}.</p>
            </div>
            <b-field 
              grouped 
              group-multiline>
              <div class="control" v-for="item in edlWords">
                <b-taglist attached>
                  <b-tag type="is-black">
                    Grade {{ item.grade }}
                  </b-tag>
                  <b-tag type="is-primary">
                    {{ item.word }}
                  </b-tag>
                </b-taglist>
              </div>
            </b-field>
          </div>
        </div>

        <div class="box">
          <b-field>
            <b-switch 
                v-model="awlWordsSwitch" 
                @input="getStats()">
              Academic Wordlist Vocabulary
            </b-switch>
          </b-field>
          <div v-if="awlWordsSwitch">
            <b-field grouped>
              <b-field>
                <b-checkbox
                    v-model="awlRelatedWordsCheckbox" 
                    @input="getStats()" 
                    size="is-small">
                  include related word forms
                </b-checkbox>
              </b-field>
            </b-field>
            <hr />
            <div 
                class="content has-text-grey has-text-centered" 
                v-if="awlWords.length == 0">
              <p>Nothing found yet.</p>
            </div>
            <b-field grouped group-multiline>
              <div class="control" v-for="item in awlWords">
                <b-taglist attached>
                  <b-tag type="is-black">
                    Sublist {{ item.sublist }}
                  </b-tag>
                  <b-tag type="is-primary">
                    {{ item.word }}
                  </b-tag>
                </b-taglist>
              </div>
            </b-field>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import api from 'services/api'

export default {
  data: function () {
    return {
      isLoading: true,
      selectedGrade: 0,
      selectedPassage: null,
      fkReadingLevelSwitch: true,
      edlWordsSwitch: true,
      edlRelatedWordsCheckbox: true,
      awlWordsSwitch: true,
      awlRelatedWordsCheckbox: true,
      data: [],
      defaultPassages: [],
      gradeLevels: [],
      passageText: '',
      edlWords: [],
      awlWords: [],
      fkScore: null,
      fkGrade: null,
      fkNotes: null,
    }
  },
  created: function() {
    api()
      .get('/passages')
      .then(response => {
        this.gradeLevels = response.data[0].gradeLevels
        this.defaultPassages = response.data[0].defaultPassages
        this.getStats()
        this.isLoading = false
        if (process.env.NODE_ENV == 'production') {
          this.$gtag.event('pageLoad')
        }
      })
  },
  methods: {
    getStats() {
      var payload = {
        "passageText": this.passageText, 
        "selectedGrade": this.selectedGrade,
        "edlWordsSwitch": this.edlWordsSwitch,
        "edlRelatedWordsCheckbox": this.edlRelatedWordsCheckbox,
        "awlWordsSwitch": this.awlWordsSwitch,
        "awlRelatedWordsCheckbox": this.awlRelatedWordsCheckbox
      }
      api()
        .post('/passages/get_stats', payload)
        .then(response => {
          this.data = response.data[0]
          this.fkScore = this.data.fkScore
          this.fkGrade = this.data.fkGrade
          this.fkNotes = this.data.fkNotes
          if (this.fkScore == 0) {
            this.setFkPlaceholders()
          }
          this.edlWords = this.data.edlWords
          this.awlWords = this.data.awlWords
          if (process.env.NODE_ENV == 'production') {
            this.$gtag.event('getStats')
          }
        })
    },
    getVocab() {
      console.log('GET VOCAB')
      // RL/TODO separate out stats and vocab calls
    },
    getPassage(id) {
      // RL/TODO: REFACTOR ALL OF THIS
      if (id == null) {
        // there is no passage id, do nothing
        // this can get triggered by resetting the drop-down value
      } else {
        // get the passage
        if (this.passageText) {
          // confirm they want to overwrite their text
          this.$buefy.dialog.confirm({
            message: 'This will delete any text you may have entered.',
            type: 'is-danger',
            onConfirm: () => {
              api()
                .post('/passages/get_passage/' + id)
                .then(response => {
                  this.passageText = response.data[0].passageText
                  this.getStats()
                  if (process.env.NODE_ENV == 'production') {
                    this.$gtag.event('getPassage')
                  }
                })
            }
          })
        } else {
          // Just go ahead they have no saved text
          api()
            .post('/passages/get_passage/' + id)
            .then(response => {
              this.passageText = response.data[0].passageText
              this.getStats()
              if (process.env.NODE_ENV == 'production') {
                this.$gtag.event('getPassage')
              }
            })
        }
      }
    },
    deletePassage() {
      this.$buefy.dialog.confirm({
        message: 'This will delete any text you may have entered.',
        type: 'is-danger',
        onConfirm: () => {
          this.passageText = ''
          this.selectedPassage = ''
          this.edlWords = []
          this.setFkPlaceholders()
        }
      })        
    },
    setFkPlaceholders() {
      this.fkScore = "Hard to say"
      this.fkGrade = "Not much to work with"
      this.fkNotes = "Need a full sentence or select a sample passage"
    }
  },
  computed: {
    wordCount: function() {
      return this.passageText.split(" ").length - 1
    },
  }
}
</script>

<style scoped>
</style>
