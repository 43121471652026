<template>
  <b-navbar>
      <template slot="brand">
          <b-navbar-item tag="router-link" :to="{ path: '/' }">
              <strong>Passage Factory</strong>
          </b-navbar-item>
      </template>
      <template slot="start">
      </template>

      <template slot="end">
        <b-navbar-item tag="div">
              <!-- <div class="buttons">
                  <a class="button is-primary">
                      <strong>Sign up</strong>
                  </a>
                  <a class="button is-light">
                      Log in
                  </a>
              </div> -->
          </b-navbar-item>
      </template>
  </b-navbar>
</template>

<script>
import api from 'services/api'

export default {
  data: function () {
    return {
    }
  },
  methods: {

  },
}
</script>
